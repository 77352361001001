/* mixins/_forms.scss */

@mixin form {
  //
}

@mixin form-header {
  //
}

@mixin form-heading {
  //
}

@mixin form-subheading {
  //
}

@mixin form-fieldset {
  @include reset-fieldset;
}

@mixin form-legend {
  //
}

@mixin form-group {
  margin-bottom: rem(30px);
}

@mixin form-label {
  @include typography(golf);
}

@mixin form-description {
  @include typography(india);
}

@mixin form-input {
  --padding-block: #{rem(8px)};
  --padding-inline: calc(var(--padding-block) * 2);
  @include typography(hotel);
  appearance: none;
  background-color: $color-white;
  border: rem(2px) solid $color-grey;
  display: block;
  padding: var(--padding-block) var(--padding-inline);
  transition-duration: 0.2s;
  width: 100%;

  &:hover {
    //
  }

  &:focus {
    outline: none;
  }
}

@mixin form-textarea {
  @include form-input;
  max-width: 100%;
  min-height: rem(200px);
}

@mixin form-select {
  @include form-input;
  --background-width: #{rem(10px)};
  --background-height: #{rem(6px)};
  --background-size: var(--background-width) var(--background-height);
  background-image: svg-url(
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="5 25 90 50.87"><path fill="#{$color-black}" d="m50 61.7 34.98-34.98a5.87 5.87 0 0 1 8.3 8.3L54.15 74.15A5.851 5.851 0 0 1 50 75.87a5.851 5.851 0 0 1-4.15-1.72L6.72 35.02a5.87 5.87 0 1 1 8.3-8.3L50 61.7Z"/></svg>'
  );
  background-position: center right var(--padding-inline);
  background-repeat: no-repeat;
  background-size: var(--background-size);
  padding-right: calc((var(--padding-inline) * 2) + var(--background-width));
}

@mixin form-option {
  color: var(--color-grey-dark);
}

@mixin form-checkbox {
  @include size(1em);
  @include typography(golf);
  align-items: center;
  appearance: none;
  background-color: $color-white;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 9px 9px;
  border: rem(2px) solid $color-grey;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  margin-right: 0.5em;
  transition-duration: 0.2s;

  &:hover {
    //
  }

  &:focus {
    outline: none;
  }

  &:checked {
    background-image: svg-url(
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85.9 85.9"><path fill="#{$color-black}" d="M72.9 0 43 30 13 0 0 12.9l30 30-30 30 12.9 12.9 30-30 30 30 12.9-12.9-30-30 30-30L72.9 0z"/></svg>'
    );
  }
}

@mixin form-radio {
  @include form-checkbox;
  background-size: rem(7px);
  border-radius: 50%;

  &:checked {
    background-image: svg-url(
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5 5"><path fill="#{$color-black}" d="M2.5 0C3.9 0 5 1.1 5 2.5S3.9 5 2.5 5 0 3.9 0 2.5 1.1 0 2.5 0z"/></svg>'
    );
  }
}

@mixin form-button {
  //
}
