/* components/_chart.scss */

.chart {
  width: 100%;
}

.ct-label {
  @include typography(foxtrot);
  display: block;

  &.ct-vertical {
    @include typography(foxtrot);
  }
}

.ct-bar {
  @include rfs(rem(60px), stroke-width);
  stroke: $color-grey !important;
  // Note: default to hidden because we're animating the bars once
  // IntersectionObserver triggers, but need to load a completed version of the
  // chart on load to prevent the page height from changing and throwing off the
  // anchor link scroll distances. This prevents a jump between full-height bars
  // and zero-height bars when the animation starts.
  visibility: hidden;

  .chart.is-observed & {
    visibility: visible;
  }
}
