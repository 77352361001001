/* components/_figure.scss */

.figure {
  position: relative;
}

.figure__container {
  @include rfs(rem(60px), padding);
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.figure__background {
  @include position(absolute, 0 0 0 0);
  @include size(100%);
  fill: $color-alpha;
  // object-fit: contain;
  // object-position: center;
  z-index: -1;
}

.figure__heading {
  @include typography(delta);
}

.figure__content {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}
