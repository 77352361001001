/* components/_number.scss */

.number {
  text-align: center;
}

.number__value {
  @include typography-style(alpha);
  @include font-size(140px);
  display: block;
  line-height: 1;
}

.number__label {
  @include typography(delta);
  display: block;
}
