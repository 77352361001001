/* base\_global.scss */

html {
  box-sizing: border-box;
  font-feature-settings: "lnum" 1;
  height: 100%;
  scroll-behavior: smooth;
}

body {
  min-height: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

img,
svg {
  height: auto;
  max-width: 100%;
  vertical-align: top;
}
