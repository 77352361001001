/* webfonts/_basis-grotesque.scss */

/*
* Web Fonts from colophon-foundry.org
*
* The fonts included in this stylesheet are subject to the End User License you purchased
* from Colophon Foundry. The fonts are protected under domestic and international trademark and
* copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
* distributing this font software.
*
* (c) 2019 Colophon Foundry
*
* Licenced to Mike Tighe Solid Site Inc.
*/

@font-face {
  font-family: "Basis Grotesque";
  src: url("../webfonts/basis-grotesque-regular-pro.eot");
  src: url("../webfonts/basis-grotesque-regular-pro.eot?#iefix")
      format("embedded-opentype"),
    url("../webfonts/basis-grotesque-regular-pro.woff2") format("woff2"),
    url("../webfonts/basis-grotesque-regular-pro.woff") format("woff"),
    url("../webfonts/basis-grotesque-regular-pro.ttf") format("truetype");
  font-weight: $font-weight-regular;
  font-style: normal;
}

@font-face {
  font-family: "Basis Grotesque";
  src: url("../webfonts/basis-grotesque-bold-pro.eot");
  src: url("../webfonts/basis-grotesque-bold-pro.eot?#iefix")
      format("embedded-opentype"),
    url("../webfonts/basis-grotesque-bold-pro.woff2") format("woff2"),
    url("../webfonts/basis-grotesque-bold-pro.woff") format("woff"),
    url("../webfonts/basis-grotesque-bold-pro.ttf") format("truetype");
  font-weight: $font-weight-bold;
  font-style: normal;
}
