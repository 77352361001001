/* base\_zindex.scss */

.background {
  z-index: -1;
}

.browserupgrade {
  z-index: 100;
}

.masthead {
  z-index: 10;
}

.section {
  //
}
