/* components/_wysiwyg.scss */

.wysiwyg {
  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }

  p {
    @include typography(delta);
    @include rfs(rem(45px), margin-bottom);
    @include rfs(rem(45px), margin-top);
  }

  ul {
    @include reset-list;
  }

  li {
    @include typography(delta);
    @include rfs(rem(30px), margin-bottom);
    @include rfs(rem(30px), margin-top);
    padding-left: 2em;
    position: relative;
  }

  li::before {
    @include position(absolute, 0 null null 0.75em);
    content: "•";
  }

  a {
    color: inherit;
  }

  img {
    //
  }
}
