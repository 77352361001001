/* components/_section.scss */

.section {
  @include rfs(rem(60px), padding-bottom);
  @include rfs(rem(60px), padding-top);
  // Prevent overflow-x due to .section__illustration going outside bounds.
  overflow: hidden;
}

.section__container {
  margin-bottom: rem(-30px);
}

.section__header {
  @include rfs(rem(45px), margin-bottom);
}

.section__heading {
  @include typography(alpha);
}

.section__subheading {
  @include typography(bravo);
}

.section__subheading a {
  color: inherit;
}

.section__content {
  margin-bottom: rem(30px);
}

.section__list {
  @include reset-list;
  margin-bottom: rem(30px);
}

.section__item {
  margin-bottom: rem(30px);
}

.section__link {
  @include typography(bravo);
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.section__link::after {
  @include size(rem(33px), rem(15px));
  background-image: svg-url(
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="5 30 90 39"><path fill="#{$color-grey}" d="M83.43 53.961c-1.653 2.479-10.98 9.681-11.452 11.57-.354 1.535.236 2.834 1.063 3.424 2.597 2.007 4.368-.472 5.667-1.653l9.563-8.855c4.841-4.014 8.501-5.785 5.785-10.508-2.361-4.014-9.799-10.508-13.932-13.577l-5.077-3.778c-2.597-1.181-5.431 1.417-4.25 4.25.59 1.417 13.105 9.917 14.286 12.633-13.577.59-27.273.945-40.85 1.535l-31.405.472c-3.542 0-7.202-.708-7.792 2.715-.59 4.132 3.424 3.778 6.848 3.778h10.39c14.64 0 26.564-.945 40.85-1.299 6.729-.235 13.577-.825 20.306-.707z"></path></svg>'
  );
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
  content: "";
  display: inline-block;
  margin-left: 0.25em;
  transition-duration: 0.2s;
  transition-property: transform;
}

.section__link:hover::after,
.section__link:focus::after {
  transform: translateX(0.25em);
}

.section__illustration.illustration {
  margin-bottom: rem(30px);
}
