/* components\_buttons.scss */

button {
  @include typography(base);
  @include reset-button;
  cursor: pointer;
}

@mixin button {
  @include typography(juliett);
  $padding-vertical: 6px;
  $padding-horizontal: $padding-vertical * 3;
  background: $color-grey;
  border-radius: rem(2px);
  border: rem(1px) solid $color-grey;
  color: $color-white;
  cursor: pointer;
  display: inline-block;
  font-weight: $font-weight-bold;
  padding: rem($padding-vertical) rem($padding-horizontal);
  text-decoration: none;
  vertical-align: middle;

  &:not([disabled]):hover,
  &:not([disabled]):focus {
    background-color: $color-white;
    border-color: $color-grey;
    color: $color-grey;
    text-decoration: none;
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.button--block {
    padding: rem($padding-vertical);
  }
}

.button {
  @include button;
}

@mixin button--invert {
  background-color: $color-white;
  border-color: $color-white;
  color: $color-grey;

  &:not([disabled]):hover,
  &:not([disabled]):focus {
    background: $color-grey;
    border-color: $color-white;
    color: $color-white;
  }
}

.button--invert {
  @include button--invert;
}

@mixin button--large {
  $padding-vertical: 11px;
  $padding-horizontal: $padding-vertical * 3;
  font-size: rem(20px);
  padding: rem($padding-vertical) rem($padding-horizontal);

  &.button--block {
    padding: rem($padding-vertical);
  }
}

.button--large {
  @include button--large;
}

@mixin button--small {
  $padding-vertical: 6px;
  $padding-horizontal: $padding-vertical * 3;
  font-size: rem(14px);
  padding: rem($padding-vertical) rem($padding-horizontal);

  &.button--block {
    padding: rem($padding-vertical);
  }
}

.button--small {
  @include button--small;
}

@mixin button--block {
  display: block;
  text-align: center;
  width: 100%;
}

.button--block {
  @include button--block;
}

.button__icon {
  @include size(1em);
  fill: currentColor;
}

.button__text {
  //
}
