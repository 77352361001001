/* components/_error.scss */

.error {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
}

.error__heading {
  @include typography(alpha);
  margin-bottom: 0;
}

.error__subheading {
  @include typography(echo);
  margin-bottom: 0;
  margin-top: 0;
}
