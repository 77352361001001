/* components/_footer.scss */

.footer {
  @include rfs(rem(60px), padding-bottom);
  @include rfs(rem(60px), padding-top);
  background-color: $color-grey;
  color: $color-white;
}

.footer__list {
  @include reset-list;
  display: flex;
  margin-bottom: rem(30px);

  .footer__link:hover,
  .footer__link:focus {
    color: #999;
  }
}

.footer__item {
  margin-right: rem(5px);
}

.footer__link {
  color: $color-white;
}

.footer__label {
  @include hide-visually;
}

.footer__icon {
  @include size(rem(28px));
  fill: currentColor;
}

.footer__separator {
  display: inline-block;
  margin-left: 0.25em;
  margin-right: 0.25em;
}

.footer__content {
  display: flex;
  gap: $layout-gutter;
  justify-content: space-between;
}

.footer__subcontent {
  //
}
