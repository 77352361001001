/* components/_navigation.scss */

.navigation {
  align-items: center;
  display: flex;
}

.navigation__date {
  @include typography(golf);
  color: inherit;
  display: block;
  margin-right: rem(20px);
  white-space: nowrap;
}

.navigation__year {
  display: inline-block;
}

.navigation__month {
  display: inline-block;

  &:not(:last-child) {
    margin-right: 0.3em;
  }
}

.navigation__dropdown {
  background-color: $color-white;
  flex-grow: 1;
  position: relative;
  width: rem(330px);
}

.navigation__heading {
  border: rem(2px) solid $color-grey;
  margin-bottom: 0;

  @include media("<=medium") {
    @include typography(india);
  }

  @include media(">medium") {
    @include typography(golf);
  }
}

$navigation-button-padding: 11.5px;
$navigation-button-arrow-width: 16px;

.navigation__button {
  display: inline-block;
  font-size: inherit;
  position: relative;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;

  @include media("<=medium") {
    $padding-vertical: $navigation-button-padding * 0.75;
    $padding-horizontal: round($padding-vertical * 2);
    $padding-horizontal-extra: $navigation-button-arrow-width;
    padding: rem($padding-vertical) rem($padding-horizontal);
    padding-right: rem(($padding-horizontal * 2) + $padding-horizontal-extra);
  }

  @include media(">medium") {
    $padding-vertical: $navigation-button-padding;
    $padding-horizontal: round($padding-vertical * 2);
    $padding-horizontal-extra: $navigation-button-arrow-width;
    padding: rem($padding-vertical) rem($padding-horizontal);
    padding-right: rem(($padding-horizontal * 2) + $padding-horizontal-extra);
  }

  &:hover,
  &:focus {
    outline: none; // a11y hazard
  }
}

.navigation__button-label {
  //
}

.navigation__button::after {
  @include position(absolute, 50% null null null);
  @include size(rem($navigation-button-arrow-width), rem(10px));
  background-image: svg-url(
    '<svg height="100" width="100" viewBox="3 23 95 55"><path fill="#{$color-grey}" d="M2.5 30l7.6-7.5L50 62.4l39.9-39.9 7.6 7.5L50 77.5z"></path></svg>'
  );
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
  content: "";
  display: block;
  margin-left: 0.5em;
  transform: translateY(-50%);

  @include media("<=medium") {
    right: rem(($navigation-button-padding * 0.75) * 2);
  }

  @include media(">medium") {
    right: rem($navigation-button-padding * 2);
  }

  .navigation.is-toggled & {
    transform: translateY(-50%) rotate(180deg);
  }
}

.navigation__list {
  @include reset-list;
  @include position(absolute, 100% 0 null 0);
  background-color: $color-grey;
  border: rem(2px) solid $color-grey;
  display: none;
  padding-bottom: rem(20px);
  padding-top: rem(20px);

  .navigation.is-toggled & {
    display: block;
  }
}

.navigation__item {
  //
}

.navigation__link {
  @include typography-style(hotel);
  color: $color-white;
  display: block;
  padding: rem(5px) rem(25px);
  text-decoration: none;

  @include media("<=medium") {
    @include typography-scale(juliett);
  }

  @include media(">medium") {
    @include typography-scale(hotel);
  }

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
