/* components/_subsection.scss */

.subsection {
  @include rfs(rem(60px), padding-bottom);
  @include rfs(rem(60px), padding-top);
}

.subsection__container {
  margin-bottom: rem(-30px);
}

.subsection__header {
  @include rfs(rem(45px), margin-bottom);
}

.subsection__heading {
  @include typography(alpha);
}

.subsection__content {
  margin-bottom: rem(30px);
}
