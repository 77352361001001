/* _config.scss */

// Colors
// -----------------------------------------------------------------------------

$color-black: #000000;
$color-grey: #111111;
$color-white: #ffffff;

$color-alpha: #518d5e;
$color-bravo: #008a56;
$color-charlie: #08a4a0;
$color-delta: #6ec693;
$color-echo: #4487a0;
$color-foxtrot: #977b3a;
$color-golf: #d69b35;
$color-hotel: #e2ad58;
$color-india: #ffa79b;
$color-juliett: #e36642;
$color-kilo: #f1582f;
$color-lima: #e93328;
$color-mike: #a86e8e;
$color-november: #953950;
$color-oscar: #c7b493;

$color-border: $color-grey;

$colors: (
  black: $color-black,
  white: $color-white,
);

$backgrounds: (
  default: $color-white,
);

// Typography
// -----------------------------------------------------------------------------

// Available styles: medium (500), medium italic (500)
$font-family-serif: "Lyon", $font-stack-georgia;

// Available styles: regular (400), regular italic (400)
$font-family-sans: "Basis Grotesque", $font-stack-helvetica;

$font-weight-thin: 200;
$font-weight-light: 300;
$font-weight-book: 400;
$font-weight-regular: 400;
$font-weight-roman: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-demi: 700;
$font-weight-bold: 700;
$font-weight-extrabold: 800;
$font-weight-black: 900;

@mixin line-height($font-size, $line-height) {
  line-height: strip-unit($line-height / $font-size);
}

// `<h1-mega>`
$font-size-mega: 90px;
$line-height-mega: 90px;

@mixin typography-scale-mega {
  @include font-size($font-size-mega);
  @include line-height($font-size-mega, $line-height-mega);
}

@mixin typography-style-mega {
  color: $color-grey;
  font-family: $font-family-serif;
  font-weight: $font-weight-medium;
}

// `<h1>`
$font-size-alpha: 54px;
$line-height-alpha: 72px;

@mixin typography-scale-alpha {
  @include font-size($font-size-alpha);
  @include line-height($font-size-alpha, $line-height-alpha);
}

@mixin typography-style-alpha {
  color: $color-grey;
  font-family: $font-family-serif;
  font-weight: $font-weight-medium;
}

// `<h2>`
$font-size-bravo: 42px;
$line-height-bravo: 54px;

@mixin typography-scale-bravo {
  @include font-size($font-size-bravo);
  @include line-height($font-size-bravo, $line-height-bravo);
}

@mixin typography-style-bravo {
  color: $color-grey;
  font-family: $font-family-sans;
  font-weight: $font-weight-regular;
}

// `<h3>`
$font-size-charlie: 36px;
$line-height-charlie: 48px;

@mixin typography-scale-charlie {
  @include font-size($font-size-charlie);
  @include line-height($font-size-charlie, $line-height-charlie);
}

@mixin typography-style-charlie {
  color: $color-grey;
  font-family: $font-family-sans;
  font-weight: $font-weight-regular;
}

// `<h4>`
$font-size-delta: 30px;
$line-height-delta: 42px;

@mixin typography-scale-delta {
  @include font-size($font-size-delta);
  @include line-height($font-size-delta, $line-height-delta);
}

@mixin typography-style-delta {
  color: $color-grey;
  font-family: $font-family-sans;
  font-weight: $font-weight-regular;
}

// `<h5>`
$font-size-echo: 24px;
$line-height-echo: 36px;

@mixin typography-scale-echo {
  @include font-size($font-size-echo);
  @include line-height($font-size-echo, $line-height-echo);
}

@mixin typography-style-echo {
  color: $color-grey;
  font-family: $font-family-sans;
  font-weight: $font-weight-regular;
}

// `<h6>`
$font-size-foxtrot: 21px;
$line-height-foxtrot: 32px;

@mixin typography-scale-foxtrot {
  @include font-size($font-size-foxtrot);
  @include line-height($font-size-foxtrot, $line-height-foxtrot);
}

@mixin typography-style-foxtrot {
  color: $color-grey;
  font-family: $font-family-sans;
  font-weight: $font-weight-regular;
}

// <p-large>
$font-size-golf: 20px;
$line-height-golf: 34px;

@mixin typography-scale-golf {
  @include font-size($font-size-golf);
  @include line-height($font-size-golf, $line-height-golf);
}

@mixin typography-style-golf {
  color: $color-grey;
  font-family: $font-family-sans;
  font-weight: $font-weight-regular;
}

// <p>
$font-size-hotel: 18px;
$line-height-hotel: 30px;

@mixin typography-scale-hotel {
  @include font-size($font-size-hotel);
  @include line-height($font-size-hotel, $line-height-hotel);
}

@mixin typography-style-hotel {
  color: $color-grey;
  font-family: $font-family-sans;
  font-weight: $font-weight-regular;
}

// <p-small>
$font-size-india: 16px;
$line-height-india: 26px;

@mixin typography-scale-india {
  @include font-size($font-size-india);
  @include line-height($font-size-india, $line-height-india);
}

@mixin typography-style-india {
  color: $color-grey;
  font-family: $font-family-sans;
  font-weight: $font-weight-regular;
}

// <small>
$font-size-juliett: 14px;
$line-height-juliett: 18px;

@mixin typography-scale-juliett {
  @include font-size($font-size-juliett);
  @include line-height($font-size-juliett, $line-height-juliett);
}

@mixin typography-style-juliett {
  color: $color-grey;
  font-family: $font-family-sans;
  font-weight: $font-weight-regular;
}

// ----------

$font-size-base: $font-size-hotel;
$line-height-base: $line-height-hotel;

@mixin typography-scale-base {
  @include typography-scale-hotel;
}

@mixin typography-style-base {
  @include typography-style-hotel;
}

// Media Queries
// -----------------------------------------------------------------------------

/**
 * Breakpoints used in the site, and provided to the `include-media` Sass mixin
 * @param  {List} a list of px values
 * @see http://include-media.com/documentation/#variable-breakpoints
 */
$breakpoints: (
  xsmall: 320px,
  small: 480px,
  medium: 768px,
  large: 1024px,
  xlarge: 1200px,
);

// Layout
// -----------------------------------------------------------------------------

/**
 * Breakpoints passed to the layout-generating mixin/functions.
 * @param  {List} a list of px values
 */
$layout-breakpoints: (xsmall, small, medium, large, xlarge);

/**
 * `max-width` of the layout elements (e.g. layout container)
 * @type {String} px value
 */
$layout-width: 1200px;

/**
 * Number of columns in the layout
 * @type {Number}
 */
$layout-columns: 12;

/**
 * Width of the gutter between layout elements
 * @type {String} px value
 */
$layout-gutter: 30px;
