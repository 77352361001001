/* components/_illustration.scss */

.illustration {
  @include rfs(rem(-525px), margin-top);
  @include rfs(rem(500px), padding-top);
  background: linear-gradient(rgba(#424536, 0) 0%, rgba(#424536, 1) 50%);
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: rem(1600px);
}

.illustration__image {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
