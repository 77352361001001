/* components/_index.scss */

.index {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
}

.index__logo {
  @include size(80vw, auto);
  max-width: rem(300px);
}
