/* components/_tooltip.scss */

.tooltip {
  background-color: $color-grey;
  opacity: 0;
  padding: rem(30px);
  padding-top: rem(50px);
  // Need to disable pointer-events when hidden, because we're only using
  // visibility: hidden (and not display: none) for easy in-and-out transitions.
  pointer-events: none;
  transition-duration: 0.2s;
  transition-property: opacity, transform, visibility;
  text-align: left;
  visibility: hidden;

  @include media("<=medium") {
    @include position(fixed, 0 0 0 0);
    @include size(100%);
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    transform: scale(0.95);
    z-index: 99999;
  }

  @include media(">medium") {
    @include position(absolute, 50% 10% null 10%);
    transform: translateY(-50%) scale(0.95);
  }
}

.tooltip__heading {
  @include typography(foxtrot);
  color: $color-white;
  font-weight: $font-weight-bold;
  margin-bottom: 10px;
  margin-top: 0;
}

.tooltip__subheading {
  color: $color-white;
  margin-bottom: 0;
  margin-top: 0;
}

.tooltip__button {
  @include position(absolute, rem(15px) rem(15px) null null);
  @include size(rem(20px));
  background-image: svg-url(
    '<svg height="100" width="100" xmlns="http://www.w3.org/2000/svg" viewBox="19 19 808 808"><path fill="#{$color-white}" d="M19 88l69-69 335 336L759 19l68 69-335 335 335 336-68 68-336-335L88 827l-69-68 336-336z"></path></svg>'
  );
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  transition-duration: 0.2s;
  transition-property: opacity;

  &:hover,
  &:focus {
    opacity: 0.5;
    outline: none;
  }
}
