/* components/_pages.scss */

.pages {
  counter-reset: pages;
  width: 100%;
}

.pages__container {
  @include reset-list;
  @include rfs(rem(-45px), margin-bottom);
  width: 100%;
}

.page {
  @include rfs(rem(45px), margin-bottom);
  counter-increment: pages;
  display: flex;
  justify-content: space-between;
  position: relative;

  @include media("<=medium") {
    flex-wrap: wrap;
    padding-left: rem($layout-gutter * 1.5);
  }

  @include media(">medium") {
    flex-wrap: nowrap;
    padding-left: rem($layout-gutter * 2);
  }
}

.page::before {
  @include position(absolute, 0 null null 0);
  @include typography-style(alpha);
  content: "#" counter(pages);

  @include media("<=medium") {
    @include typography-scale(foxtrot);
  }

  @include media(">medium") {
    @include typography-scale(delta);
  }
}

.page__label {
  @include typography-style(delta);

  @include media("<=medium") {
    @include typography-scale(foxtrot);
    margin-bottom: rem(10px);
  }

  @include media(">medium") {
    @include typography-scale(delta);
    flex-basis: 75%;
  }
}

.page__value {
  @include typography-style(alpha);
  text-align: right;

  @include media("<=medium") {
    @include typography-scale(charlie);
    flex-basis: 100%;
    text-align: left;
  }

  @include media(">medium") {
    @include typography-scale(alpha);
    flex-basis: 25%;
    margin-left: rem($layout-gutter);
  }
}
