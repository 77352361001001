/* base/_forms.scss */

form {
  @include form;
}

fieldset {
  @include form-fieldset;
}

legend {
  @include form-legend;
}

label {
  @include form-label;
}

option {
  @include form-option;
}

#{$all-text-inputs} {
  @include form-input;
}

textarea {
  @include form-textarea;
}

select {
  @include form-select;
}

input[type="checkbox"] {
  @include form-checkbox;
}

input[type="radio"] {
  @include form-radio;
}
