.form {
  //
}

.form__header {
  @include form-header;
}

.form__heading {
  @include form-heading;
}

.form__subheading {
  @include form-subheading;
}

.form__fieldset {
  @include form-fieldset;
}

.form__legend {
  @include form-legend;
}

.form__group {
  @include form-group;
}

.form__subgroup {
  align-items: center;
  display: flex;
  margin-top: rem(5px);

  .form__label {
    @include typography(hotel);
  }
}

.form__label {
  @include form-label;
}

.form__description {
  @include form-description;
}

.form__input {
  @include form-input;
}

.form__textarea {
  @include form-textarea;
}

.form__select {
  @include form-select;
}

.form__checkbox {
  @include form-checkbox;
}

.form__radio {
  @include form-radio;
}

.form__button {
  @include form-button;
}

.form__submit {
  @include form-button;
}

.form__message {
  @include form-input;
  background-color: var(--background-color);
  border: unset;
  color: var(--color);
}

.form__message--success {
  --background-color: #deffe9;
  --color: #00bc3e;
}

.form__message--error {
  --background-color: #ffeded;
  --color: #ff0000;
}
