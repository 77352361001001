/* components/_masthead.scss */

$masthead-height: 160px;

html {
  // Offset to prevent the { position: fixed } masthead from overlapping the
  // first x pixels of each anchor target.

  @include media("<=medium") {
    scroll-padding-top: $masthead-height / 2;
  }

  @include media(">medium", "<=1920px") {
    // Note: rfs() will automatically convert all values into rem, but in this
    // specific instance we need px as rem() doesn't calculate properly on the
    // <html> element due to the way font-size is setup.
    scroll-padding-top: calc(32.2px + 6.65625vw);
  }

  @include media(">1920px") {
    scroll-padding-top: $masthead-height;
  }
}

.masthead {
  // Act as a vertical placeholder for the { position: fixed }
  // .masthead__container child element.
  color: $color-grey;
  position: relative;
  transition-duration: 0.2s;
  transition-property: color;

  @include media("<=medium") {
    height: rem($masthead-height / 2);
  }

  @include media(">medium") {
    @include rfs(rem($masthead-height), height);
  }

  &.is-scrolled {
    color: $color-white;
  }
}

.masthead::before {
  content: "";
  display: block;
  height: rem(60px);
}

.masthead__container {
  @include position(fixed, 0 0 null 0);
  align-items: center;
  background-color: $color-white;
  display: flex;
  transition-duration: 0.2s;
  transition-property: background-color;

  @include media("<=medium") {
    height: rem($masthead-height / 2);
  }

  @include media(">medium") {
    @include rfs(rem($masthead-height), height);
  }

  .masthead.is-scrolled & {
    background-color: $color-grey;
  }
}

.masthead__content {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.masthead__logo {
  flex-shrink: 0;
  fill: $color-grey;
  margin-right: rem($layout-gutter);
  transition-duration: 0.2s;
  transition-property: fill;

  @include media("<=medium") {
    @include size(rem(85px), rem(25px));
  }

  @include media(">medium") {
    @include size(rem(136px), rem(40px));
  }

  .masthead.is-scrolled & {
    fill: $color-white;
  }
}
