/* components/_header.scss */

.header {
  @include rfs(rem(60px), padding-bottom);
  @include rfs(rem(60px), padding-top);
}

.header__container {
  //
}

.header__content {
  //
}

.header__heading {
  @include typography(mega);
  margin-bottom: 0;
  margin-top: 0;
}

.header__subheading {
  @include typography(charlie);
  margin-bottom: 0;
  margin-top: 0;
}
