/* components/_sources.scss */

.sources {
  counter-reset: sources;
  width: 100%;
}

.sources__container {
  @include reset-list;
  @include rfs(rem(-45px), margin-bottom);
  width: 100%;
}

.source {
  @include rfs(rem(45px), margin-bottom);
  align-items: center;
  counter-increment: sources;
  position: relative;

  @include media("<=medium") {
    padding-left: rem($layout-gutter * 1.5);
  }

  @include media(">medium") {
    padding-left: rem($layout-gutter * 2);
  }
}

.source::before {
  @include position(absolute, 0.25em null null 0);
  @include typography-style(alpha);
  content: "#" counter(sources);

  @include media("<=medium") {
    @include typography-scale(foxtrot);
  }

  @include media(">medium") {
    @include typography-scale(delta);
  }
}

.source__content {
  @include media("<=medium") {
    display: block;
  }

  @include media(">medium") {
    align-items: center;
    display: flex;
  }
}

.source__label {
  @include typography-style(delta);
  position: relative;

  @include media("<=medium") {
    @include typography-scale(foxtrot);
    display: block;
    margin-bottom: rem(10px);
  }

  @include media(">medium") {
    @include typography-scale(delta);
  }
}

.source__label::after {
  @include media(">medium") {
    content: "-";
    margin-left: 0.25em;
    margin-right: 0.25em;
  }
}

.source__value {
  @include typography-style(alpha);

  @include media("<=medium") {
    @include typography-scale(charlie);
    display: block;
    flex-basis: 100%;
    margin-bottom: rem(10px);
    text-align: left;
  }

  @include media(">medium") {
    @include typography-scale(bravo);
  }
}

.source__bar {
  background-color: $color-grey;
  display: block;
  height: rem(20px);
  // 2s = matches duration of countup.js
  transition-duration: 2s;
  transition-property: width;

  // Prevent animation until observed (by InteractionObserver)
  &:not(.is-observed) {
    width: 0% !important;
  }
}
