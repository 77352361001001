/* webfonts/_lyon.scss */

/*
 * This font software is the property of Commercial Type.
 *
 * You may not modify the font software, use it on another website, or install it on a computer.
 *
 * License information is available at http://commercialtype.com/eula
 * For more information please visit Commercial Type at http://commercialtype.com or email us at info[at]commercialtype.com
 *
 * Copyright (C) 2020 Schwartzco Inc.
 * License: 2007-CCGNGR
 */

@font-face {
  font-family: "Lyon";
  src: url("../webfonts/LyonDisplay-Medium-Web.woff2") format("woff2"),
    url("../webfonts/LyonDisplay-Medium-Web.woff") format("woff");
  font-weight: $font-weight-light;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Lyon";
  src: url("../webfonts/LyonDisplay-MediumItalic-Web.woff2") format("woff2"),
    url("../webfonts/LyonDisplay-MediumItalic-Web.woff") format("woff");
  font-weight: $font-weight-light;
  font-style: italic;
  font-stretch: normal;
}
