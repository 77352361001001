/* components/_comparison.scss */

.comparison {
  @include reset-list;
  display: flex;
  position: relative;
  width: 100%;
}

.comparison::after {
  @include position(absolute, 0 null null 50%);
  @include size(rem(1px), 100%);
  background-color: $color-grey;
  content: "";
  display: block;
}

.comparison__item {
  flex-basis: 50%;
  flex-shrink: 0;
  padding-bottom: rem(15px);
  padding-top: rem(15px);
  text-align: center;
  width: 50%;

  &:first-child {
    @include rfs(rem($layout-gutter), padding-right);
  }

  &:last-child {
    @include rfs(rem($layout-gutter), padding-left);
  }
}

.comparison__label {
  @include typography(echo);
  display: block;
  margin-bottom: rem(15px);
}

.comparison__value {
  @include typography-style(alpha);
  @include font-size(120px);
  display: block;
  line-height: 1;
}
