/* components/_loading.scss */

.loading {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
}

.loading__icon {
  @include size(100px);
  stroke: $color-grey;
}

.loading__heading {
  @include typography(alpha);
  margin-bottom: 0;
}

.loading__subheading {
  @include typography(echo);
  margin-bottom: 0;
  margin-top: 0;
}
