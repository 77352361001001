/* components/_resources.scss */

$resource-width: 496px; // 370px;
$resource-height: 427px; // 295px;

.resources {
  margin-bottom: rem(30px);

  &.is-observed {
    //
  }
}

.resources__container {
  margin-bottom: rem(-30px);
}

.resource {
  display: block;
  margin-bottom: rem(30px);
  margin-left: auto;
  margin-right: auto;
  position: relative;
  text-align: center;
  text-decoration: none;

  @include media(">medium") {
    max-width: rem($resource-width);
  }
}

.resource::before {
  @include media(">medium") {
    content: "";
    display: block;
    padding-bottom: percentage($resource-height / $resource-width);
  }
}

.resource__background {
  @include position(absolute, 0 0 0 0);
  @include size(100%);
  z-index: -1;

  @include media(">medium") {
    object-fit: contain;
    object-position: center;
  }
}

.resource__content {
  @include rfs(rem(60px), padding);

  @include media(">medium") {
    @include position(absolute, 0 0 0 0);
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.resource__heading {
  @include typography(delta);

  @include media("<=medium") {
    margin-bottom: 0;
  }
}

.resource__link {
  color: $color-grey;
  text-decoration: none;

  .resource:hover &,
  .resource:focus & {
    text-decoration: underline;
  }
}

.resource__link::after {
  @include size(rem(15px), rem(7px));
  background-image: svg-url(
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="5 30 90 39"><path fill="#{$color-grey}" d="M83.43 53.961c-1.653 2.479-10.98 9.681-11.452 11.57-.354 1.535.236 2.834 1.063 3.424 2.597 2.007 4.368-.472 5.667-1.653l9.563-8.855c4.841-4.014 8.501-5.785 5.785-10.508-2.361-4.014-9.799-10.508-13.932-13.577l-5.077-3.778c-2.597-1.181-5.431 1.417-4.25 4.25.59 1.417 13.105 9.917 14.286 12.633-13.577.59-27.273.945-40.85 1.535l-31.405.472c-3.542 0-7.202-.708-7.792 2.715-.59 4.132 3.424 3.778 6.848 3.778h10.39c14.64 0 26.564-.945 40.85-1.299 6.729-.235 13.577-.825 20.306-.707z"></path></svg>'
  );
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
  content: "";
  display: inline-block;
  margin-left: 0.25em;
  transform: translateY(-0.1em);
  transition-duration: 0.2s;
  transition-property: transform;
}

.resource__link:hover::after,
.resource__link:focus::after {
  transform: translateY(-0.1em) translateX(0.25em);
}
