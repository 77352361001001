/* components/_blocks.scss */

$block-width: 496px;
$block-height: 427px;

.blocks {
  margin-bottom: rem(30px);

  &.is-observed {
    //
  }
}

.blocks__container {
  margin-bottom: rem(-30px);
}

.block {
  margin-bottom: rem(30px);
  margin-left: auto;
  margin-right: auto;
  position: relative;
  text-align: center;

  @include media(">medium") {
    max-width: rem($block-width);
  }
}

.block::before {
  @include media(">medium") {
    content: "";
    display: block;
    padding-bottom: percentage($block-height / $block-width);
  }
}

.block__background {
  @include position(absolute, 0 0 0 0);
  @include size(100%);
  z-index: -1;

  @include media(">medium") {
    object-fit: contain;
    object-position: center;
  }
}

.block__content {
  @include rfs(rem(60px), padding);

  @include media(">medium") {
    @include position(absolute, 0 0 0 0);
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.block__checkbox {
  @include rfs(rem(30px), margin-bottom);
  @include rfs(rem(90px), height);
  @include rfs(rem(90px), width);
  align-items: center;
  border: rem(2px) solid $color-grey;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  margin-left: auto;
  margin-right: auto;
}

.block__checkmark {
  @include rfs(rem(44px), height);
  @include rfs(rem(60px), width);
  fill: currentColor;
  stroke: $color-grey;
  stroke-dasharray: 75;
  stroke-dashoffset: 75;
  transition-duration: 0.2s;
  transition-property: stroke-dashoffset;

  // Because the blocks are displayed in a 2x2 grid, they'll likely be visible
  // in groups of 2 so we stagger every second item.
  .grid__cell:nth-child(even) & {
    transition-delay: 0.5s;
  }

  // Trigger animation once observed (by InteractionObserver)
  .block.is-observed & {
    stroke-dashoffset: 0;
  }
}

.block__heading {
  @include typography(charlie);
  margin-bottom: 0;
  margin-top: 0;
}

.block__tooltip.tooltip {
  //

  .block.is-toggled & {
    opacity: 1;
    pointer-events: initial;
    visibility: visible;

    @include media("<=medium") {
      transform: scale(1);
    }

    @include media(">medium") {
      transform: translateY(-50%) scale(1);
    }
  }
}

.block__button {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  transition-duration: 0.2s;
  transition-property: opacity;

  &:hover,
  &:focus {
    opacity: 0.5;
    outline: none;
  }
}

.block__button--open {
  @include size(1em);
  background-image: svg-url(
    '<svg height="100" width="100" xmlns="http://www.w3.org/2000/svg" viewBox="7 7 16 16"><path d="M15 23a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm-.918-6.035h1.676a2.24 2.24 0 0 1 .123-.735c.074-.193.207-.382.399-.565l.673-.627c.286-.278.493-.55.621-.815a2 2 0 0 0 .194-.884c0-.743-.24-1.315-.718-1.717-.478-.403-1.153-.604-2.024-.604-.875 0-1.555.218-2.04.654-.484.435-.73 1.043-.738 1.825h1.98c.009-.293.085-.526.23-.698a.707.707 0 0 1 .568-.257c.507 0 .761.295.761.884 0 .243-.075.464-.225.665-.15.202-.37.423-.66.666-.288.242-.497.528-.626.858-.13.33-.194.78-.194 1.35zm-.24 1.782c0 .289.105.526.314.712.209.185.47.278.782.278.312 0 .573-.093.782-.278a.91.91 0 0 0 .313-.712.91.91 0 0 0-.313-.712 1.135 1.135 0 0 0-.782-.278c-.313 0-.573.092-.782.278a.91.91 0 0 0-.314.712z" fill="#000" fill-rule="evenodd"></path></svg>'
  );
  font-size: inherit;
  margin-left: 0.35em;
  transform: translateY(-0.1em);
  vertical-align: middle;
}
