/* components/_percentage.scss */

.percentage {
  text-align: center;
}

.percentage__value {
  @include typography-style(alpha);
  @include font-size(140px);
  display: block;
  line-height: 1;
}

.percentage__label {
  @include typography(hotel);
  display: block;
}
